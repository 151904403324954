import { Navigation, Pagination } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const optionsMostSold: CarouselProps = {
  slidesPerView: "auto",
  breakpoints: {
    1024: {
      slidesPerView: "auto",
      slidesPerGroup: 6
    }
  },
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}

export const smOptionsCardsWithCTA: CarouselProps = {
  slidesPerView: "auto",
  grabCursor: true,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30-bigger-padding"
}

export const mdOptionsCardsWithCTA: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 4,
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}
